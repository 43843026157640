import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Buttom, Button } from "react-bootstrap";
import { ArrowList } from "../components/arrow_list/arrowList";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/landing-empresas-nueva.jpg";
import './empresas.css';
import { Link } from 'gatsby'
import Faq from "../components/faq/faq";
import { apiRequest } from "../apiRquest/apiRequest";
import { SolicitarInfo } from "../components/solicitud_de_info/SolicitarInfo";
let text = `Potenciá tu empresa en el mercado de capitales.`;
const LandingEmpresas = () => {
    const [faq, setFaq] = useState({
        list: []
    });
    const pageTitle = 'Potenciá tu Empresa en el Mercado de Capitales | Balanz';
    const pageDesc = 'Aumentá la productividad de tu empresa con las herramientas que te brinda el mercado. Invertí en Fondos Comunes de Inversión, Echeqs y mucho más.';
    const listProfesional = [
        'Tenemos 20 años de trayectoria y experiencia.',
        'Contamos con un equipo de asesores financieros especializados en empresas.',
        'Brindamos atención personalizada.',
        'Ofrecemos recomendaciones a medida para inversiones en pesos o en dólares, sin mínimos.',
        'Facilitamos el acceso al crédito para capital de trabajo (corto y mediano plazo) y proyectos de inversión (largo plazo).',
        'Impulsamos el crecimiento de tu empresa al conseguir más alternativas de financiamiento al mejor costo del mercado.',
        'Maximizamos las posibilidades de financiamiento a través de acuerdos comerciales con SGRs y bancos.'

    ];
    useEffect(() => {
        apiRequest
            .get("/v1/faq/16")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })
                })
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerEmpresas"
                bannerTrasparecy="bannerMask"
                productTitle="Abrí una cuenta empresa"
                bannerImage={Banner}
                bannerImageClassName="EmpresasBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText2"
                bannerImageMobile={Banner}
                mobile={false}
                className='title-last-line'
            />

            <section className="fullwidth page-description pb-0">
                <Container>
                    <p className="base-text">
                        <strong>Te acercamos las alternativas de financiamiento que el mercado de capitales ofrece para que desarrolles tu negocio.</strong>
                    </p>
                    <Button variant="secondary" href='https://www.balanz.com/abrir-cuenta-juridica.aspx?reference=gcarrasco@balanz.com'>Abrir cuenta empresa</Button>
                </Container>
            </section>


            <section className="fullwidth page-description pb-0" >
                <Container>
                    <Row className="pb-5 pt-3">
                        <h2 className="mt-2 pb-2 pb-md-3"><span className="secondary-color">¿Por qué elegir a Balanz como el socio estratégico de tu empresa?</span></h2>

                        <ArrowList list={listProfesional} />
                    </Row>
                </Container>
                <SolicitarInfo/>


                <section className="fullwidthbg part-blue  info-empresas-section" id="fullwidthbg-inst">
                    <Container id="fullwidth-container">
                        <Row id="column-row">
                            <Col xs={12} md={12} lg={12} className="column col-text">
                                <div className="section-content text-center" id="secction-id">
                                    <h3 className="titulo mx-auto" ><span>Instrumentos financieros para hacer crecer tu empresa.</span></h3>
                                    <hr/>
                             </div>
                            </Col>
                            <Col xs={12} md={12} lg={12} className="column col-text valores-container"> 
                                <div className="section-content valores valores-items" >
                                    <Link  to="/inversiones/fondos"><h3>Fondos</h3></Link>
                                    <Link  to="/inversiones/bonos"><h3>Bonos</h3></Link>
                                    <Link className="btna" to="/inversiones/futuros"><h3>Futuros</h3></Link>
                                </div>
                                <div className="section-content valores valores-items">
                                <Link className="btna" to="/inversiones/cedears"><h3>CEDEARS</h3></Link>
                                <Link className="btna" to="/inversiones/acciones"><h3>Acciones</h3></Link>   
                                <Link className="btna" to="/inversiones/cauciones"><h3>Cauciones</h3></Link>    
                                </div>
                                <div className="section-content valores valores-items">
                                <Link className="btna" to="/inversiones/obligaciones-negociables"><h3>ONs</h3></Link>
                                <Link className="btna" to="/inversiones/echeqs"><h3>Echeqs</h3></Link>   
                                <Link className="btna" to="/inversiones/opciones"><h3>Opciones</h3></Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'opciones-faq'} />
                </Row>
              </Container>
        </section>
            </section>
        </>
    );

    return <main>
        <Layout title={pageTitle} description={pageDesc} childrem={page} category={"empresas"}></Layout>
    </main>
}
export default LandingEmpresas
